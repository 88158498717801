import theme from "../theme";
import {makeStyles} from "@material-ui/styles";
import { Button, MenuItem, Select, Typography } from "@material-ui/core";
import { Add, Close, Remove } from "@material-ui/icons";
import React, {useContext, useEffect, useState} from "react";
import formatPrice from "functions/priceFormat";
import parsePrices from "functions/parseSWPrices";
import {TrackingContext} from "../providers/tracking";
import ProductThumbnail from "../products/thumbnail";
import Plus from "public/images/icons/plus.svg";
import Minus from "public/images/icons/minus.svg";
import Link from "next/link";
import parseOrderPrices from "../../tools/parseOrderPrices";

const useStyles = makeStyles({
	item: {
		position: 'relative',
		display: 'flex',
		padding: '12px 0px',
		'&:not(:first-of-type)': {
			borderBottom: '1px solid #f3f3f5',
		},
		'&.locked': {
			pointerEvents: 'none'
		},
	},
	item__image: {
		position: 'relative',
		marginRight: 15,
		flexShrink: 0,
		width: 120,
		'.compact &': {
			width: 80
		},
		[theme.breakpoints.down('sm')]: {
			width: 90,
		},
		'&:after': {
			content: '""',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			position: 'absolute',
			boxShadow: 'inset 0 0 90px 8px #f3f3f5',
			mixBlendMode: 'multiply',
			pointerEvents: 'none'
		}
	},
	item__info: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginLeft: 15,
		minWidth: 0,
		[theme.breakpoints.down('xs')]: {
			position: 'relative',
			width: 'calc(100% - 24px)',
			marginLeft: 0
		}
	},
	item__name: {
		fontSize: 13,
		fontWeight: 300,
		lineHeight: 1.5,
		color: "#000",
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	item__variant: {
		display: 'inline-block',
		fontSize: 13,
		fontWeight: 300,
		color: '#333',
	},
	item__priceQty: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 'auto'
	},
	item__prices: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: 'auto',
		[theme.breakpoints.down('xs')]: {
			order: 5,
			display: 'flex',
			alignItems: 'flex-end',
			marginRight: 0,
			fontSize: 13
		},
		'@media (max-width:320px)': {
			flexDirection: 'row'
		}
	},
	item__price: {
		fontSize: 13,
		lineHeight: '38px',
		color: '#666',
		'&.special': {
			color: '#ca0d0d'
		},
		'&.old': {
			marginRight: 10,
			textDecoration: 'line-through',
			color: '#999'
		},
		'.compact &': {
			lineHeight: '32px',
		}
	},
	qtyWrapper: {
		display: 'flex',
		marginTop: 'auto'
	},
	qtyButton: {
		width: 38,
		height: 38,
		minWidth: 'auto',
		padding: 10,
		textAlign: 'center',
		appearance: 'none',
		cursor: 'pointer',
		border: '1px solid #f3f3f5',
		borderRadius: 0,
		backgroundColor: '#f3f3f5',
		'&.increment': {
			borderRadius: 0
		},
		'.compact &': {
			width: 32,
			height: 32
		},
	},
	qtyIcon: {
		fontSize: 20,
		height: 20,
		width: 20,
		fontWeight: 400,
		strokeWidth: 0.35
	},
	qtyInput: {
		width: 38,
		padding: '0.5rem 0',
		margin: 0,
		textAlign: 'center',
		fontSize: 14,
		fontFamily: theme.typography.fontFamily,
		appearance: 'textfield',
		border: 'none',
		outline: 'none',
		backgroundColor: '#f5f5f5',
		borderTop: '1px solid #f3f3f5',
		borderBottom: '1px solid #f3f3f5',
		borderRadius: 0,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16
		},
		'&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
			appearance: 'none',
			margin: 0
		},
		'.compact &': {
			width: 32,
			height: 32,
			fontSize: 13
		},
	},
	item__remove: {
		position: 'absolute',
		top: 12,
		right: 0,
		padding: 2,
		color: '#fff',
		fontSize: 20,
		cursor: 'pointer',
		backgroundColor: '#000',
		borderRadius: '50%',
		[theme.breakpoints.down('sm')]: {
			top: 0,
			fontSize: 20
		},
	},
}, {name: "CheckoutCartItem"});

let updateTimeout;

export default function CheckoutCartItem({item, isLocked, setLocked, actions, suspend, resume, reload, fullWidth = false, compact = false, readOnly}) {
	const [quantity, setQuantity] = useState(item.quantity);
	const classes = useStyles();
	const tracking = useContext(TrackingContext);
	const {updateCart, removeCartItem} = actions;

	let prices = getItemPrices(item);

	useEffect(() => {
		clearTimeout(updateTimeout);
		if (item.quantity !== quantity && quantity > 0) {
			suspend(item.id);
			updateTimeout = setTimeout(async () => {
				await updateCart({item: item.id, quantity: quantity});
				await reload();
				resume(item.id);
			}, 300);
		}
	}, [quantity]);

	useEffect(() => {
		setQuantity(item.quantity);
	}, [item]);

	function getItemPrices(item) {
		return item.price ? parsePrices(item.price) : parseOrderPrices(item.payload.npPrices);
	}

	async function removeFromCart(item) {
		suspend(item.id);
		await removeCartItem({item: item.id});
		await reload();
		resume();

		removeFromCartTracking();
	}

	function removeFromCartTracking() {
		tracking.actions.removeFromCart({
			id: item.payload.productNumber,
			name: item.label,
			price: item.price.unitPrice,
			discount: item.price?.listPrice?.discount,
			manufacturer: '',
			category: '',
			variant: item.payload.options.length > 0 ? item.payload.options[0].option : '',
			list: compact ? 'minicart' : 'checkout cart',
			quantity: item.quantity
		});
	}

	function hasOptions(item) {
		return item.payload.options.length > 0;
	}

	function getVariantOption(item) {
		return item.payload.options.map((option) => {
			return option.option;
		}).join(' - ');
	}

	function getUrl() {
		return item.payload.npEnriched ? '/' + item.payload.npEnriched.seoUrls.seoPathInfo : '/detail/' + item.referencedId;
	}

	/**
	 * Item Quantity functions
	 */
	function increment() {
		setQuantity(quantity + 1);
	}

	function deincrement() {
		if (quantity > 1) {
			setQuantity(quantity - 1);
		} else {
			setQuantity(0);
			removeFromCart(item);
		}
	}

	function onChange(value) {
		setQuantity(value);
	}

	return (
		<li className={[classes.item, compact ? 'compact' : '', isLocked && isLocked !== item.id ? 'locked' : ''].join(' ')}>
			<div className={classes.item__image}>
				<Link href={getUrl()}>
					<a>
						<ProductThumbnail image={item.cover} size={120} index={0}/>
					</a>
				</Link>
			</div>

			<div className={[classes.item__info, fullWidth ? 'fullWidth' : ''].join(' ')}>
				<Link href={getUrl()}>
					<a style={{ textDecoration: 'none', paddingRight: 30 }}>
						<Typography variant="body1" className={classes.item__name}>{item.label}</Typography>

						{hasOptions(item) ?
							<Typography variant="body1" className={classes.item__variant}>
								{getVariantOption(item)}
							</Typography> : ''
						}
					</a>
				</Link>

				<Close className={classes.item__remove} onClick={removeFromCart.bind(this, item)}/>

				<div className={classes.item__priceQty}>
					<div className={classes.qtyWrapper}>
						<Button onClick={deincrement} className={[classes.qtyButton, 'deincrement'].join(' ')}><Minus
							className={classes.qtyIcon}/></Button>
						<input type="number" autocomplete="off" autofill="off" value={quantity} name={'item-input-' + item.payload.productNumber} onChange={(event) => onChange(event.target.value)}
							   className={classes.qtyInput}/>
						<Button onClick={increment} className={[classes.qtyButton, 'increment'].join(' ')}><Plus
							className={classes.qtyIcon}/></Button>
					</div>

					<div className={classes.item__prices}>
						{prices.isOnSale ?
							<>
								<Typography variant="body1"
											className={[classes.item__price, 'old'].join(' ')}>{formatPrice(prices.listPrice)}</Typography>
								<Typography variant="body1"
											className={[classes.item__price, 'special'].join(' ')}>{formatPrice(prices.price)}</Typography>
							</> :
							<Typography variant="body1"
										className={classes.item__price}>{formatPrice(prices.price)}</Typography>
						}
					</div>
				</div>
			</div>
		</li>
	);
}
